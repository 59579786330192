import axios from 'axios';
import prependApiUrl from '../utils/prepend-api-url';

import { IValentinesContestValues } from '../formik/valentines-contest-form.config';
import { IValentinesContestWinnerValues } from '../formik/valentines-contest-form-winner.config';

export const valentinesFormMutation = (data: IValentinesContestValues) => {
    return axios.post(prependApiUrl('/walentynki-zabka/create'), {
        ...data,
    });
};

export const postValentinesWinner = (createForm: IValentinesContestWinnerValues) => {
    return axios.post(prependApiUrl('/walentynki-zabka/addresses'), {
        ...createForm,
    });
};

export const getValentinesCheckToken = (token: string) => {
    return axios.get(prependApiUrl(`/walentynki-zabka/check/${token}`), {});
};
