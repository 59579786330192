// extracted by mini-css-extract-plugin
export var backgroundAnimation = "valentines-winner-form-module--backgroundAnimation--f092e";
export var button = "valentines-winner-form-module--button--a36b8";
export var container = "valentines-winner-form-module--container--8da06";
export var error = "valentines-winner-form-module--error--549c8";
export var errorBlink = "valentines-winner-form-module--error-blink--933f4";
export var fields = "valentines-winner-form-module--fields--e5b9a";
export var header = "valentines-winner-form-module--header--ffbac";
export var icon = "valentines-winner-form-module--icon--8d655";
export var info = "valentines-winner-form-module--info--3dd8b";
export var input = "valentines-winner-form-module--input--c4d04";
export var loading = "valentines-winner-form-module--loading--9a6f1";
export var title = "valentines-winner-form-module--title--82ecd";